.orm {
  &-btn {
    border-radius: $border-radius-buttons;
    background-color: $orm_cta_button_color;
    border: 0;
    color: $white;
    padding: 6px 12px;
    font-size: rem-calc(14px);
    font-weight: 600;
    font-family: "Open Sans";
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  &-btn--outline {
    background-color: transparent;
    color: $orm_cta_button_color;
    padding: rem-calc(14px 25px);
    border-radius: $border-radius-buttons;
    border: 2px solid $orm_cta_button_outline_color;
    transition: all 0.5s ease;

    &:hover, &:focus{
      background-color: $orm_cta_button_outline_color;
      color: $white;
    }
  }
  &-btn--large {
    height: calc(48px);
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  &-btn--searchbar {
    height: calc(48px);
    font-family: "Open Sans";
    font-size: rem-calc(14px);
    font-weight: 600;
    transition: background-color ease-in-out 0.25s, color ease-in-out 0.25s;
    &:focus {
      outline: none;
    }
    &.disable {
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      background-color: $orm_cta_button_hover_color;
    }
    @media (min-width: "420px") and (max-width: "576px") {
      max-width: rem-calc(250px);
    }
    @media (min-width: "646px") and (max-width: "768px") {
      max-width: rem-calc(250px);
    }
    @media (min-width: $md-viewport) and (max-width: $lg-viewport) {
      max-width: rem-calc(250px);
    }
  }
}

.orm-carsearchfilter {
  color: $orm_searchbar_color;
  max-width: rem-calc(990px);
}
.orm-carsearchfilter_row {
  background-color: $orm_searchbar_bg;
  @media only screen and (min-width: $sm-viewport) {
    border-radius: 0 $border-radius-big 0 0;
  }

  &--rounded {
    border-radius: 0 0 $border-radius-big $border-radius-big;
  }
}
.orm-carsearchfilter__types {
  //   border-radius: $border-radius-big $border-radius-big 0 0;
  //
  color: $orm_white;

  width: 100%;

  .fa-w-18 {
    width: 18px;
  }

  &item {
    cursor: pointer;
    padding: 20px 40px;
    transition: all 0.5s ease;
    user-select: none;
    background: rgba($customer_color_grey_dark, 0.95);
    &:first-child {
      border-radius: $border-radius-big $border-radius-big 0 0;
      @media only screen and (min-width: $sm-viewport) {
        border-radius: $border-radius-big 0 0 0;
      }
    }
    &:last-child {
      @media only screen and (min-width: $sm-viewport) {
        border-radius: 0 $border-radius-big 0 0;
      }
    }
    &.active,
    &:hover,
    &:focus,
    &:active {
      background-color: $orm_searchbar_bg;
      color: $orm_searchbar_color;
    }
    &:hover {
      box-shadow: 0px -6px 5px 0px rgba(0, 0, 0, 0.05) inset;
    }
    &.active {
      box-shadow: 0 0 0 0 transparent;
    }
  }
}

/* orm default colors */

$orm_black: #24282c;
$orm_dk_bluegrey: #25313e;
$orm_dk_grey: #979797;
$orm_md_grey: #d3d6d8;
$orm_lt_grey: #f7f7f7;
$orm_white: #ffffff;
$orm_red: #de1f21; // original design color, changed with aria conform : #e42022
$orm_lt_red: #ff0000;
$orm_green: #18cb69;
$orm_orange: #ff9824;
$orm_turquise: #00a0bf;
$orm_blue: #0682e3;

$orm_txt_dark_color: $orm_dk_bluegrey;
$orm_txt_light_color: $orm_white;

$orm_txt_headline_searchbar: $orm_dk_bluegrey;
$orm_txt_headline_locationmap: $orm_dk_bluegrey;

$orm_form_border_color: $orm_dk_grey;
$orm_form_bg_color: $orm_white;
$orm_form_text_inactive_color: $orm_dk_bluegrey;
$orm_form_bg_inactive_color: $orm_lt_grey;
$orm_form_checked_color: $orm_green;
$orm_form_focus_color: $orm_black;
$orm_form_placeholder_color: $orm_md_grey;
$orm_form_error_color: $orm_red;
$orm_calendar_selected_color: $orm_dk_bluegrey;
$orm_calendar_not_available_color: $orm_md_grey;
$orm_calendar_occupied_color: $orm_red;
$orm_calendar_partially_available: $orm_orange;

$orm_bg_dark_color: $orm_black;
$orm_bg_lt_grey_color: $orm_lt_grey;
$orm_bg_light_color: $orm_white;

$backgroundcta1: $orm_bg_lt_grey_color;
$backgroundcta2: $orm_white;

$orm_reservation_bg: $orm_bg_lt_grey_color;
$orm_reservation_inner_bg: $orm_white;
$orm_reservation_borderleft: $orm_md_grey;

$orm_cta_button_color: $orm_red;
$orm_cta_button_hover_color: $orm_lt_red;

$orm_cta_button_outline_color: $orm_red;
$orm_cta_button_outline_hover_color: $orm_lt_red;
$orm_cta_button_outline_border_hover: $orm_lt_red;
$orm-cta-button-outline-hover-text-color: $orm_white;

$orm_cta2_button_color: $orm_white;
$orm_cta2_button_hover_color: $orm_white;

$orm_cta2_button_outline_color: $orm_red;
$orm_cta2_button_outline_hover_color: $orm_lt_red;
$orm_cta2_button_outline_border_hover: $orm_txt_dark_color;

$orm_searchbar_bg: $orm_white;
$orm_searchbar_color: $orm_txt_dark_color;

$orm_search_input_border: $orm_dk_grey;
$orm_search_input_bg: $orm_white;
$orm_search_input_color: $orm_txt_dark_color;

$orm_reservation_input_border: $orm_white;
$orm_reservation_input_bg: $orm_black;
$orm_reservation_input_color: $orm_white;

$orm_map_marker_rental_color: $orm_dk_bluegrey;
$orm_map_marker_sharing_color: $orm_turquise;

$orm_map_stationlist_bg1: $orm_white;
$orm_map_stationlist_bg2: $orm_bg_lt_grey_color;

$orm_map_stationlist_color1: $orm_txt_dark_color;
$orm_map_stationlist_color2: $orm_txt_dark_color;

$orm_map_input_bg: $orm_white;
$orm_map_input_color: $orm_txt_dark_color;
$orm_map_input_border: $orm_dk_grey;

$orm_header_bg: $orm_white;
$orm_header_step_color: $orm_txt_dark_color;
$orm_header_step_border: $orm_txt_dark_color;

$orm_header_step_active_color: $orm_txt_light_color;
$orm_header_step_active_bg: $orm_txt_dark_color;

$orm_header_step_checked_color: $orm_txt_light_color;
$orm_header_step_checked_bg: $orm_form_checked_color;

$orm_footer_bg: $orm_bg_dark_color;
$orm_footer_link_color: $orm_white;
$orm_footer_link_hover: $orm_turquise;

$orm_timepicker_selected: $orm_black;

$orm_loading_bar: $orm_md_grey;
$orm_loading_bar_border: $orm_dk_grey;
$orm_loading_bar_progress: $orm_red;

$orm_offers_background: rgba(#000, 0.7);
$orm_offers_text: $orm_white;
$orm_offers_dots: $orm_red;
$orm_offers_dots_inner: $orm_lt_red;

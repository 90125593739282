$offers_bp: 827px;

.orm-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  &:hover {
    .orm-slider__controll__dots {
      visibility: visible;
      opacity: 1;
    }
  }
  &__image__container {
    padding-left: rem-calc(50px);
    padding-right: rem-calc(50px);
    @media only screen and (min-width: $sm-viewport) {
      padding-left: rem-calc(80px);
      padding-right: rem-calc(80px);
    }
    @media only screen and (min-width: $lg-viewport) {
      padding-left: rem-calc(100px);
      padding-right: rem-calc(100px);
    }
  }
  &__inner {
    transition: all 2s ease;
    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__slide {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    &--hide {
      opacity: 0;
      transition: opacity 1s ease;
      @media only screen and (max-width: $offers_bp) {
        position: absolute;
      }
      &.show {
        opacity: 1;
        @media only screen and (max-width: $offers_bp) {
          position: relative;
        }
      }
    }

    &--offers {
      background-size: cover;
    }
  }
  &__controll {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  &--carcategory {
    .orm-btn {
      position: relative;
      z-index: 5;
    }
    .orm-cattitle, img{
      pointer-events: none;
      user-select: none;
    }
    .orm-slider__controll {
      position: unset;
    }
    .orm-slider__controll_arrows {
      @media (max-width: $sm-viewport) {
        opacity: 1 !important;
        .orm-slider__controll:hover & {
          opacity: 1 !important;
        }
      }
    }

    .orm-slider__controll_arrows {
      font-size: 40px;
      text-shadow: unset;
    }
    .orm-slider__inner {
      transition: all 2s ease;
      align-items: flex-end;
      flex-wrap: nowrap;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    .orm-slider__slide {
      &--hide {
        opacity: 0;
        width: 0%;
        height: 0%;
        transition: opacity 1s ease;
        animation-name: fade; // Name der Animation
        animation-duration: 1s; // Dauer der Animation
        animation-fill-mode: forwards;
        display: none;
        @media only screen and (min-width: $md-viewport) {
          &:nth-of-type(2n) {
            animation-delay: 200ms;
          }
        }
        @media only screen and (min-width: $lg-viewport) {
          &:nth-of-type(3n-1) {
            animation-delay: 200ms;
          }
          &:nth-of-type(3n) {
            animation-delay: 400ms;
          }
        }

        &.show {
          transition: opacity 1s ease;
          width: 100%;
          height: auto;
          display: block;

          @media only screen and (max-width: $offers_bp) {
            position: relative;
          }
        }
      }
    }
  }
  &--offers {
    @media only screen and (max-width: $offers_bp) {
      padding-top: 0 !important;
    }
    .orm-slider__inner > div {
      @media only screen and (max-width: $offers_bp) {
        position: relative;
        transition: opacity 2s ease;
        &.fade-leave-active {
          position: absolute;
        }
      }
    }
  }

  &__imagecontainer {
    height: 200px;

    img {
      width: auto;
      height: auto;
      max-height: 100%;
    }
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.orm-slider__content {
  border-radius: $border-radius-big;
  background-color: $orm_offers_background;
  color: $orm_offers_text;
  padding: rem-calc(32px);
  display: inline-block;
  position: absolute;
  bottom: 10vh;
  min-width: 40vw;
  max-width: 40vw;
  z-index: 10;

  @media only screen and (max-width: 1500px) {
    max-width: 50vw;
    min-width: 50vw;
  }

  @media only screen and (max-width: $offers_bp) {
    max-width: none;
    width: 100%;
    position: relative;
    bottom: 0;
    margin: rem-calc(32px) 0;
  }

  &_headline {
    font-size: rem-calc(42px);
    font-weight: 600;
    line-height: 1.2;

    @media (max-width: $sm-viewport) {
      font-size: rem-calc(32px);
    }
  }

  &_price {
    font-size: rem-calc(42px);
    font-weight: 100;
  }
}

.orm-slider__controll__dots {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  .orm-slider__controll--fade & {
    opacity: 0;
    transition: opacity 500ms ease;
  }

  @media only screen and (max-width: $sm-viewport) {
    opacity: 1 !important;
  }

  .orm-slider__controll:hover & {
    opacity: 1;
  }
}
.orm-slider__controll__dot {
  margin: 0 0.5rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}
.orm-slider__controll__dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 1px solid currentColor;
  background-color: transparent;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.orm-slider__controll__dot .default span {
  display: none;
}
.orm-slider__controll__dot--active {
  border-width: 6px;
  border-color: $orm_dk_bluegrey;
}

.orm-slider--offers .orm-slider__controll__dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 1px solid $orm_offers_dots_inner;
  background-color: transparent;
  -webkit-box-shadow: 0 0 1px rgba($orm_offers_dots, 0.5),
    0 0 3px rgba($orm_offers_dots, 0.3);
  box-shadow: 0 0 1px rgba($orm_offers_dots, 0.5),
    0 0 3px rgba($orm_offers_dots, 0.3);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.orm-slider--offers .orm-slider__controll__dot--active {
  border-width: 6px;
  border-color: $orm_offers_dots;
}

.orm-slider__controll_arrows {
  position: absolute;
  cursor: pointer;
  font-size: 26px;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 0 0 3px rgba(0, 0, 0, 0.3);

  .orm-slider__controll--fade & {
    opacity: 0;
    transition: opacity 500ms ease;
  }

  .orm-slider__controll:hover & {
    opacity: 1;
  }

  @media (max-width: $sm-viewport) {
    opacity: 0;
    .orm-slider__controll:hover & {
      opacity: 0;
    }
  }

  &:hover {
    text-shadow: 0 0 1px rgba(97, 97, 97, 0.7), 0 0 3px rgba(255, 255, 255, 0.3);
  }

  &--right {
    right: 1rem;
  }
  &--left {
    left: 1rem;
  }
}

.orm-slider--offers .orm-slider__controll_arrows {
  text-shadow: 0 0 1px rgba($orm_offers_dots, 0.3),
    0 0 3px rgba($orm_offers_dots, 0.2);
  color: rgba($orm_offers_dots, 0.1);
  font-size: rem-calc(44px);

  &:hover {
    text-shadow: 0 0 1px rgba($orm_offers_dots, 0.7),
      0 0 3px rgba($orm_offers_dots, 0.3);
    color: rgba($orm_offers_dots, 1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  //   transform: translateY(30px);s
}

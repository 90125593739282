body {
  margin: 0;
  padding: 0;

  * {
    box-sizing: border-box;
  }
}

.orm-background {
  &--cta1 {
    background-color: $backgroundcta1;
  }
  &--cta2 {
    background-color: $backgroundcta2;
  }
}

.orm-preload-page {
  font-size: 64px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 140px;

  &-home {
    padding-top: 150px;
  }

  &-static {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    width: 100%;
    .orm-reservationHeader__logo_image {
      margin: auto;
    }
  }
}
.orm-preload-page-typo3 {
  .orm-preload-page {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    width: 100%;
    .orm-reservationHeader__logo_image {
      margin: auto;
    }
  }
}
.orm-loader-bar {
  background-color: $orm_loading_bar;
  border: 1px solid $orm_loading_bar_border;
  height: rem-calc(20px);
  width: rem-calc(200px);
  padding: rem-calc(1px);
  font-size: 9px;
  text-align: center;
  position: relative;

  &__progress {
    background-color: $orm_loading_bar_progress;
    height: 100%;
    width: 0%;
    transition: width 250ms ease;
  }
}

.orm-loader-spinner {
  position: relative;
  &-text {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

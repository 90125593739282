.orm-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.45);
  top: 0;
  left: 0;
  z-index: 5000;
  &__inner{
      background-color: $white;
      border-radius: $border-radius-big;
      max-height: 500px;
      overflow: auto;

      &--fullheight{
        max-height: none;
      }
  }

  &--overflowAuto{
    .orm-modal__inner{
      overflow: unset;
    }
    .orm-stationselect__options{
      z-index: 501;
    }
  }

}

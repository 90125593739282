#inline-widget-container {

  iframe {
    min-height: 340px;
    max-width: 500px;
  }
}

.orm-payment__container {
  position: relative;
  z-index: 55;
  background-color: $orm-white;
  text-align: center;
}

.orm-receipt__container {
    // border: 2px solid black;
  background-color: $orm_lt_grey;
  display: inline-block;
  padding: 16px 16px;
  margin-top: 16px;
}

.orm-payswitch{
  font-size: 14px;
  cursor: pointer;
}